import main from './main';

export default [
    ...main
].map(layout => {
    if(layout.routes) {
        layout.routes = layout.routes.map(route => {
            if(route.routes) {
                route.routes = route.routes?.map((subroute) => {
                    if(subroute.analytics?.name && route.analytics?.name) {
                        subroute.analytics.name = [...route.analytics?.name || [], ...subroute.analytics?.name || []].filter(Boolean);
                    }
                    return subroute;
                });
            }
            return route;
        })
    }
    return layout;
});