import { lazy } from 'react';

const NotFound = lazy(() =>  import('../pages/General/NotFound'));
const DashboardPage = lazy(() =>  import('../pages/General/Dashboard'));

const general = [{
    path: '/',
    element: DashboardPage,
    exact: true,
    analytics: { name: ["Dashboard"] },
},{
    path: "*",
    element: NotFound,
    analytics: { name: ["404"]},
},{
    path: "/404",
    element: NotFound,
    analytics: { name: ["404"]},
}]

export default general;