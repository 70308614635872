import { lazy } from 'react';

const LoginPage = lazy(() =>  import('../pages/User/Login'));

const general = [{
    path: '/user/login',
    element: LoginPage,
    exact: true,
    analytics: { name: ["Login"] },
}]

export default general;