import MainLayout from "../layouts/MainLayout";
import UserLayout from "../layouts/UserLayout";
import general from "./general";
import user from "./user";
import order from "./order";

const routes =  [{
    layout: MainLayout,
    routes: [
        ...general,
        ...order
    ].map(route => ({
        ...route,
        private: true
    }))
}, {
    layout: UserLayout,
    routes: [
        ...user
    ]
}]

export default routes;