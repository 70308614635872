import React, { Fragment } from 'react';
import classNames from 'classnames'
import { Outlet } from 'react-router-dom';

export default function UserLayout() {

    const Classes = classNames({
        "relative min-h-svh w-screen": true
    })

    return (
        <Fragment>
            <main className={Classes}>
                <Outlet />
            </main>
        </Fragment>
    )
}
