// import { useQuery } from '@tanstack/react-query';
// import { getUser } from 'api/users';
// import Cookies from 'js-cookie';
import React from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
    // let user = {}
    return (
        <div className='fixed top-0 left-0 w-screen h-20 flex justify-between items-center z-50 px-5 box-border bg-dark'>
            <Link to='/'>
                <img src='/images/logo.png' alt='Tuken' width={120} height={30} className='pt-1'/>
            </Link>

            {/* {user &&
                <div className='flex items-center bg-white bg-opacity-10 px-4 py-2 text-sm text-white rounded-3xl mb-1'>{`${user?.lastName} ${user?.firstName}`}</div>
            } */}
        </div>
    );
}
