import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ErrorBoundary } from "react-error-boundary";

import routes from './routes';
import NotFoundPage from './pages/General/NotFoundPage';

import './index.css';
import './stylesheets/index.js';

const queryCache = new QueryClient({
    defaultConfig: {
        retry: 1
    },
    retry: 0
})

function fallbackRender({ error }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre style={{ color: "red" }}>{error.message}</pre>
        </div>
    );
}

function App() {

    return (
        <QueryClientProvider client={queryCache}>
            <Suspense>
                <ErrorBoundary fallbackRender={fallbackRender}>
                    <Router>
                        <Routes>
                            {routes?.map((layout, index) => {
                                return (
                                    <Route key={index} element={<layout.layout />} >
                                        {layout.routes.map((route, indexRoute) => {
                                            return (
                                                <Route
                                                    key={`${index}-${indexRoute}`}
                                                    {...route}
                                                    element={<route.element/>}
                                                >
                                                    {route?.routes?.map((subroute, subIndex) =>
                                                        <Route
                                                            key={`${subIndex}-${index}-${indexRoute}`}
                                                            {...subroute}
                                                            element={<subroute.element/>}
                                                        />
                                                    )}
                                                </Route>
                                            )
                                        })}
                                    </Route>
                                )
                            })}

                            <Route
                                path="*"
                                render={() => <NotFoundPage />}
                            />
                        </Routes>
                    </Router>
                </ErrorBoundary>
            </Suspense>

            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    )
}

export default App;
