import { lazy } from 'react';

const OrderPage = lazy(() =>  import('../pages/Order/Order'));
const NewOrderPage = lazy(() =>  import('../pages/Order/New'));
const CheckinPage = lazy(() =>  import('../pages/Order/Checkin'));
const CheckinComponentPage = lazy(() =>  import('../pages/Order/CheckinComponent'));
const CheckoutPage = lazy(() =>  import('../pages/Order/Checkout'));
const CheckinNewPage = lazy(() =>  import('../pages/Order/CheckinNew'));
const CheckoutNewPage = lazy(() =>  import('../pages/Order/CheckoutNew'));

const order = [{
    path: '/orders/add',
    element: NewOrderPage,
    exact: true,
    analytics: { name: ["Nuovo ordine"] },
}, {
    path: '/orders/:orderId',
    element: OrderPage,
    analytics: { name: ["Ordine"] },
}, {
    path: '/orders/:orderId/check-out/new',
    element: CheckoutNewPage,
    analytics: { name: ["Ordine","Check-out","Nuovo"] },
}, {
    path: '/orders/:orderId/check-out/:eventId',
    element: CheckoutPage,
    analytics: { name: ["Ordine","Check-out"] },
}, {
    path: '/orders/:orderId/check-in/new',
    element: CheckinNewPage,
    exact: true,
    analytics: { name: ["Ordine","Check-in","Nuovo"] },
}, {
    path: '/orders/:orderId/check-in/:eventId',
    element: CheckinPage,
    analytics: { name: ["Ordine","Check-in"] },
}, {
    path: '/orders/:orderId/check-in/:eventId/:questionsGroupId',
    element: CheckinComponentPage,
    analytics: { name: ["Ordine","Check-in","Componente"] },
}]

export default order;